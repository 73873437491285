<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <div class="webNew-dispflex"   >
       
       <div class="backbox" style="margin-left: 3px;">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Academic Management
       </h4>
      </div>
     <!--  style="margin-top: 5%;" -->
      <div class="row p-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex" style="padding: 10px 13px 1px;">
              <div style="width: auto">
                <!-- <h1>Academic Management</h1> -->
              </div>

              <div style="width: 14%">
                <span class="has-float-label" style="margin-top: 4%">
                  <input
                type="text"
                disabled
                style="border-bottom: none;"
                :value="currentAcademicYear"
                placeholder="Current Academic Year"
                class="form-control form-input"
              />
                  <label for="clslistid">AcademicYear </label>
                </span>
              </div>
              <div style="width: 14%">
                <span class="has-float-label" style="margin-top: 4%">
                  <input v-if="studentDetails.class"
                type="text"
                disabled
                style="border-bottom: none;"
                v-model="studentDetails.class.className"                
                class="form-control form-input"
              />
                  <label for="clslistid">Class </label>
                </span>
              </div>
              <div style="width: 14%">
                <span class="has-float-label" style="margin-top: 4%">
                  <input v-if="studentDetails.section"
                type="text"
                disabled
                style="border-bottom: none;"
                v-model="studentDetails.section.name"                
                class="form-control form-input"
              />
                  <label for="clslistid">Classroom </label>
                </span>
              </div>

            
              <div class="mt-1">
                <!-- <button type="button" class="backbtn"  @click="previousRoute">
                  <i class="fa fa-arrow-left"></i>
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2">  
        <div class="widjet" v-if="isLoader">
          <div class="p-3 widjetcontent" v-if="studentDetails.class">
            <div class="tabs" style="height: 2%; background: none">
              <!-- <input type="radio" name="tabs" id="overviewtab" checked />
              <label for="overviewtab">OverView</label>
              <div class="tab">
                <academicManagementOverView />
              </div> -->
              <input type="radio" name="tabs" id="timetab" checked/>
              <label for="timetab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Timetable</label>
              <div class="tab">
                <academicManagementTimeTable :classId="studentDetails.class._id" :classRoom="studentDetails.section._id" :terms="termList" :accountId="studentDetails.account._id" :academicYearId="studentDetails.academicYear._id"/>
              </div>
              <input type="radio" name="tabs" id="curriculumtab" />
              <label for="curriculumtab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Curriculum</label>
              <div class="tab">
                <academicManagementCurriculum :classId="studentDetails.class._id" :classRoom="studentDetails.section._id" :terms="termList" :accountId="studentDetails.account._id" :academicYearId="studentDetails.academicYear._id"/>
              </div>
              <input type="radio" name="tabs" id="assignmenttab" />
              <label for="assignmenttab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Assignments</label>
              <div class="tab">
                <academicManagementAssignments :classId="studentDetails.class._id" :classRoom="studentDetails.section._id" :terms="termList" :accountId="studentDetails.account._id" :academicYearId="studentDetails.academicYear._id"/>
              </div>
              <input type="radio" name="tabs" id="examViewTab" />
              <label for="examViewTab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Exam Schedule</label>
              <div class="tab">
                <academicManagementSubject :classId="studentDetails.class._id" :classRoom="studentDetails.section._id" :terms="termList" :accountId="studentDetails.account._id" :academicYearId="studentDetails.academicYear._id"/>
              </div>
              <input type="radio" name="tabs" id="examTab" />
              <label for="examTab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Progress Report</label>
              <div class="tab">
                <academicManagementExamResult :classId="studentDetails.class._id" :classRoom="studentDetails.section._id" :terms="termList" :accountId="studentDetails.account._id" :academicYearId="studentDetails.academicYear._id"/>
              </div>
            </div>
          </div>
        </div>
        <div class="widjet" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <span style="margin-left: 42%;" >Fetching...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";

export default {
  name: "teacher",
  data() {
    return {
      studentDetails: {},
      termList: [],
      isLoader: false
    };
  },
  created() {
    this.getStudentDetails(),
    this.getTerms()
    this.initComponent()
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    currentAcademicYear() {
    
      if (this.studentDetails && this.studentDetails.academicYear) {
        return this.studentDetails.academicYear.from + "-" + this.studentDetails.academicYear.to;
      }else{
        return ''
      }
    },
  },
  methods: {
    initComponent(){
     // this.isLoader = false
      setTimeout(() => {
        this.isLoader = true
              }, 1000);
    },
  
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
   //  this.$router.go(-1)
     this.$router.push({ path: data });
    },
    async getStudentDetails() {
      let userData = secureUI.sessionGet("user");


        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.isLoader = false
          const response = await ViService.viXGet(`/contact/getStudentDetaiils/${this.$route.params.id}`,userData.token);
          
          if (response.isSuccess) {
            this.studentDetails = response.data || {}
           // this.termList = response.data.class.terms || []
          } else {
            this.$toasted.error(response.message);
          }
        }
   
    },
    async getTerms() {
      let userData = secureUI.sessionGet("user");


        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          this.isLoader = false
          const response = await ViService.viXGet(`/contact/getStudentTerms/${this.$route.params.id}`,userData.token);
          
          if (response.isSuccess) {
          //  this.studentDetails = response.data || {},
            this.termList = response.data || []
          } else {
            this.$toasted.error(response.message);
          }
        }
   
    },
  },
  components: {
    academicManagementfilter: () => import("./ViTeacherAcademicManagementFilter.vue"),
    academicManagementTimeTable: () => import("./ViTeacherAcademicManagementTimeTable.vue"),
    academicManagementCurriculum: () => import("./ViTeacherAcademicManagementCurriculum.vue"),
    academicManagementAssignments: () => import("./ViTeacherAcademicManagementAssignments.vue"),
    academicManagementExamResult: () => import("./ViTeacherAcademicManagementExamResult.vue"),
    academicManagementSubject: () => import("./ViTeacherAcademicManagementSubject.vue"),

    
  },
};
</script>

