<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" :key="componentKey">
      <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Class Management
       </h4>
      </div>
     <!--  style="margin-top: 5%;" style="position: fixed;margin-right: 21px;" -->
     <div class="form-row" >
      <div class="form-group input-group col-md-1 mt-3 pr-md-3" >
          
        </div>
        <div class="form-group input-group col-md-2 mt-3 pr-md-3" style="padding-top: 8px;padding-left: 95px;">
          Select Class
        </div>
       
        <div class="form-group input-group col-md-2 mt-3 pr-md-3">
          <select
                    class="form-control form-input"
                    id="deptlistid"
                    style="border-radius: 4px;text-align: center;background: white;"
                    v-model="selectedClass"
                    @change="getSection(selectedClass)"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
        </div>
       
        <div class="form-group input-group col-md-2 mt-3 pr-md-3" style="padding-top: 8px;padding-left: 53px;">
          Select Classroom
        </div>
       
        <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                  <select
                   @change="checkValueChanged"
                    class="form-control form-input"
                    id="deptlistid"
                    style="border-radius: 4px;text-align: center;background: white;"
                    :disabled="!selectedClass"
                    v-model="selectedSection"
                  >
                    <option :value="null">-- Select --</option>

                    <option
                      v-for="(item, index) in sectionList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
        </div>
        <div class="form-group input-group col-md-1 mt-3 pr-md-3" >
          
        </div>
      </div>
     
      <div class="p-2" >   
        <div class="widjet" v-if="selectedClass && isLoader" >
          <div class="p-3 widjetcontent">
            <div class="tabs" style="height: 2%; background: none">
              <input type="radio" name="tabs" id="overviewtab" :checked="currentTab == 1 ? 'checked' : ''" @click="setActiveTab(1)" />
              <label for="overviewtab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Overview</label>
              <div class="tab">
                <academicManagementOverView :classId="selectedClass" :classRoom="selectedSection"/>
              </div>
              <input type="radio" name="tabs" id="EnrollStudentTab" :checked="currentTab == 2 ? 'checked' : ''" @click="setActiveTab(2)" />
              <label for="EnrollStudentTab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Attendance</label>
              <div class="tab">
                <academicManagementStudentAttendance :classId="selectedClass" :classRoom="selectedSection"/>
              </div>
              <input type="radio" name="tabs" id="gradeSettingTab" :checked="currentTab == 3 ? 'checked' : ''" @click="setActiveTab(3)"/>
              <label for="gradeSettingTab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Subjects</label>
              <div class="tab" >
                <academicManagementSubject :classId="selectedClass" :classRoom="selectedSection"/>
              </div>
              <input type="radio" name="tabs" id="timetab" :checked="currentTab == 4 ? 'checked' : ''" @click="setActiveTab(4)"/>
              <label for="timetab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Timetable</label>
              <div class="tab">
                <academicManagementTimeTable :classId="selectedClass" :classRoom="selectedSection"/>
              </div>
              <input type="radio" name="tabs" id="curriculumtab" :checked="currentTab == 5 ? 'checked' : ''" @click="setActiveTab(5)"/>
              <label for="curriculumtab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Curriculum</label>
              <div class="tab">
                <academicManagementCurriculum :classId="selectedClass" :classRoom="selectedSection" :assignedSubjects="assignedSubjects"/>
              </div>
              <input type="radio" name="tabs" id="assignmenttab" :checked="currentTab == 6 ? 'checked' : ''" @click="setActiveTab(6)"/>
              <label for="assignmenttab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Assignments</label>
              <div class="tab">
                <academicManagementAssignments :classId="selectedClass" :classRoom="selectedSection" :assignedSubjects="assignedSubjects" @call-parent="parentMethod"/>
              </div>
              <input type="radio" name="tabs" id="examTab" :checked="currentTab == 7 ? 'checked' : ''" @click="setActiveTab(7)"/>
              <label for="examTab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Exam Result</label>
              <div class="tab">
                <academicManagementExamResult ref="childRef" :classId="selectedClass" :classRoom="selectedSection" :sectionListView="sectionList" :classListView="classList"/>
              </div>
              <input type="radio" name="tabs" id="contributionTab" :checked="currentTab == 8 ? 'checked' : ''" @click="setActiveTab(8)"/>
              <label for="contributionTab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Contribution</label>
              <div class="tab">
                <academicManagementContribution :classId="selectedClass" :classRoom="selectedSection" @childEvent="handleChildEvent"/>
              </div>
              <input type="radio" name="tabs" id="ssss" :checked="currentTab == 9 ? 'checked' : ''" @click="setActiveTab(9)"/>
              <label for="ssss"></label>
              <div class="tab">
              </div>
              
            </div>
          </div>
        </div>
        <div class="widjet" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
            <span style="margin-left: 42%;" v-if="!isLoader">Fetching...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";

export default {
  name: "teacher",
  data() {
    return {
      userDetail: {},
      previousSelectedSection: null,
      isLoader: true,
      classList: [],
      sectionList: [],
      selectedClass: null,
      selectedSection: null,
      assignedSubjects: [],
      currentTab: 1,
      componentKey: 0
    };
  },
  created() {
    this.getClassWithSection();
    this.getUserTeacherList()
    this.initComponent()

  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },
  methods: {
    setActiveTab(index) {
      this.currentTab = index;
    },
    parentMethod() {
      this.componentKey += 1; // Update key to force component refresh
      this.currentTab = 6;
    },
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
    initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.userDetail = userData.userDetails;

        // this.selectedBranch = localStorage.getItem("currentBranch");
      }
    },
    async handleChildEvent(message) {
      this.$refs.childRef.callfromParentCmp()
     // this.$refs.childRef.getOverAllPerformanceInfo(null);
      // this.$refs.childRef.getOverAllPerformanceInfo(null)
      // this.$refs.childRef.getContributionHeaders()
      // this.$refs.childRef.getStudentContributions()
     // this.messageFromChild = message;
    },
    checkValueChanged(){
      // if(this.selectedSection){
        this.isLoader = false
      setTimeout(() => {
        this.isLoader = true
          let userData = secureUI.sessionGet("user");
        
        if( userData.userDetails.profile.name == "Teacher"){
          this.assignedSubjects = []
          let getSubjects = this.sectionList.find((s) => s._id == this.selectedSection);
          
          if(getSubjects){
            for(let y of getSubjects.assignedTeachers){
              if(y.teacherId && y.teacherId == userData.userDetails._id){
               this.assignedSubjects.push(y.subjectId)
               // tempSections.push(x._id)

              }
            }
          }
         // this.assignedSubjects = this.assignedSubjects.filter( x => x._id == this.selectedSection)
        }
        }, 1000);
     // }
   
    //   let tempValue = this.selectedSection
    //   if(this.previousSelectedSection == null && this.selectedSection){
    //     this.previousSelectedSection = this.selectedSection
    //   }
       
    //   if(this.previousSelectedSection == this.selectedSection){

    //   }
    //   if((this.previousSelectedSection != this.selectedSection) && this.previousSelectedSection){
    //     this.selectedSection = null
    //     this.selectedSection = tempValue
    //   }
    },
    getSection(classId) {
      this.selectedSection = null
      this.assignedSubjects = []
      let tempSections = []
    //  const letters = new Set();
      let allSections = this.classList.find((x) => x._id == classId).sections;

      let userData = secureUI.sessionGet("user");
      

      if( userData.userDetails.profile.name == "Teacher"){
        let sections = allSections.filter( x => x.assignedTeachers.length > 0)

        
        if(sections.length > 0){
          for(let x of sections){
            for(let y of x.assignedTeachers){
              if(y.teacherId && y.teacherId == userData.userDetails._id){
               // this.assignedSubjects.push(y.subjectId)
                tempSections.push(x._id)

              }
            }
          }
          // Create a Set from the array to remove duplicates
          let uniqueSet = new Set(tempSections);

          // Convert the Set back to an array
          let uniqueArray = Array.from(uniqueSet);

          //sections

          this.sectionList = sections.filter((s) => uniqueArray.includes(s._id));

          this.selectedSection = sections.length > 0 ? sections[0]._id : null
       }
     
      
      //  assignedSubjects
      }
      else{
        this.sectionList = allSections

        this.selectedSection = this.sectionList.length > 0 ? this.sectionList[0]._id : null

       }
       this.checkValueChanged()
    },
    
    async getClassWithSection() {
      let userData = secureUI.sessionGet("user");

       if( userData.userDetails.profile.name == "Teacher"){

        const response = await ViService.viXGet("/teacher/getAssignedTeacherwithClassSectionList?viewType=list",userData.token);

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
      
          this.$store.dispatch("setClassAndSectionList", resultData.data);
          this.classList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }

       }else{
        let getClassCounts = this.$store.getters.getClassAndSectionList;

            if (getClassCounts.length == 0) {
            if (!userData) {
              this.$toasted.error("Please login and do the action");
              this.$router.push("/login");
            } else {
              const response = await ViService.viXGet(
                `/academic/class_with_sections`,
                userData.token
              );

              if (response.isSuccess) {
                const resultData = secureUI.secureGet(response.data);
                this.$store.dispatch("setClassAndSectionList", resultData.data);
                this.classList = resultData.data;
              } else {
                this.$toasted.error(response.message);
              }
            }
          } else {
            this.classList = this.$store.getters.getClassAndSectionList;
          }   
       }

       if(this.classList.length > 0){
        this.selectedClass = this.classList[0]._id
        await this.getSection(this.selectedClass)
       }

       //selectedClass: null,
      //selectedSection: null,
     
    },
    async getUserTeacherList() {
      let userData = secureUI.sessionGet("user");

      let getUserCounts = this.$store.getters.getUserTeacherList;


      if (getUserCounts.length == 0) {
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {

          const response = await ViService.viXGet("/teacher/getUserTeacherList?viewType=list",userData.token);

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);

            this.$store.dispatch("setUserTeacherList", resultData.teacherList);
            //this.classList = resultData.data;
          } else {
            this.$toasted.error(response.message);
          }
        }
      } 
      // else {
      //   this.classList = this.$store.getters.getClassAndSectionList;
      // }

     
    },
  },
  components: {
    academicManagementfilter: () =>
      import("./ViTeacherAcademicManagementFilter.vue"),
    academicManagementTab: () =>
      import("./ViTeacherAcademicManagementTabs.vue"),
    academicManagementOverView: () =>
      import("./ViTeacherAcademicManagementOverView.vue"),
    academicManagementTimeTable: () =>
      import("./ViTeacherAcademicManagementTimeTable.vue"),
    academicManagementCurriculum: () =>
      import("./ViTeacherAcademicManagementCurriculum.vue"),
    academicManagementAssignments: () =>
      import("./ViTeacherAcademicManagementAssignments.vue"),
    academicManagementExamResult: () => import("./ViTeacherAcademicManagementExamResult.vue"),
    academicManagementContribution: () => import("./ViTeacherAcademicManagementContribution.vue"),

    academicManagementGradeSetting: () => import("./ViTeacherAcademicManagementGradeSetting.vue"),
    academicManagementSubject: () => import("./ViTeacherAcademicManagementSubject.vue"),
    academicManagementStudentAttendance: () => import("./ViTeacherAcademicManagementStudentAttendance.vue"),


    
    
  },
};
</script>

