<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Inbox
       </h4> 
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
           
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                
                <input type="radio" name="tabs" id="stafftab" checked/>
                <label for="stafftab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Leave Approval</label>
                <div class="tab">
                  <div class="form-row">
              <div class="form-group col-md-12 mt-3">
                <div class=" tbllist">
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell">Term</div> -->
                      <div class="cell" style="width: 20%">Student Name</div>
                      <div class="cell">Class</div>
                      <div class="cell">Classroom</div>
                      <div class="cell">From </div>
                      <div class="cell">To</div>
                      <div class="cell">No Of Days</div>
                      <div class="cell">Applied Date</div>
                      <div class="cell">Status</div>
                      <div class="cell">Action</div>
                    </div>
                    <div
                  class="resrow"
                  v-for="(item, index) in studentApplyLeaveRequest"
                  :key="index"
                >
                  <div class="cell">{{ item.student ? item.student.firstName : '' }}</div>
                  <div class="cell">{{ item.class ? item.class.className : '' }}</div>
                  <div class="cell">{{ item.section ? item.section.name : '' }}</div>

                  <div class="cell">{{ item.from | dateFormat }}</div>
                  <div class="cell">{{ item.to | dateFormat }}</div>
                  <div class="cell">{{ item.noOfDates.length }}</div>
                  
                  <div class="cell">{{ item.createdDate | dateFormat }}</div>
                  <div class="cell" :style="item.status == 'Pending Approval' ? 'color: darkorange;' : '' ">{{ item.status || '' }}</div>
                  <div class="cell">
                    <button
                  class="btn round orange tooltipt"
                  @click.prevent="openActionPopUp(item,index)"
                >
                  <i class="far fa-eye"></i
                  ><span class="tooltiptext">View</span>
                </button>
                    
                  </div>
                </div>
                  
                  </div>
                  
                </div>
              </div>
                </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  id="visitorTracking"
                />
                <label for="visitorTracking" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Visitor Tracking</label>
                <div class="tab">

                  <visitorTracking/>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  id="Announcement "
                />
                <label for="Announcement " style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Announcement </label>
                <div class="tab">

                  <alertsAndNotifications/>
                </div>
                <input type="radio" name="tabs" id="archive" />
                <label for="archive" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Archive</label>
                <div class="tab">
                 
                 
                  <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell">Term</div> -->
                      <div class="cell" style="width: 24%">Student Name</div>
                      <div class="cell">Class</div>
                      <div class="cell">Classroom</div>
                      <div class="cell">From </div>
                      <div class="cell">To</div>
                      <div class="cell">No Of Days</div>
                      <div class="cell">Status</div>
                    </div>
                    <div
                  class="resrow"
                  v-for="(item, index) in sortedAttendanceList"
                  :key="index"
                >
                  <div class="cell">{{ item.student ? item.student.firstName + " " + item.student.lastName: '' }}</div>
                  <div class="cell">{{ item.class ? item.class.className : '' }}</div>
                  <div class="cell">{{ item.section ? item.section.name : '' }}</div>

                  <div class="cell">{{ item.from | dateFormat }}</div>
                  <div class="cell">{{ item.to | dateFormat }}</div>
                  <div class="cell">{{ item.noOfDates.length }}</div>
                  
                  <div class="cell" style="color: rgb(0, 255, 55);">{{ item.status || '' }}</div>
                 
                </div>
                  
                  </div>
                  
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        id="viewActionPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
      <button type="button" class="close"  @click.prevent="closeActionPopUp">×</button>

      <!-- <button type="button" aria-label="Close" class="close" style="border: darkgray;border-style: solid;" @click.prevent="closePoup">×</button> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Leave Applied</h2>
          <br />
          <div>
            <form
              data-vv-scope="admissionValidate"
              id="crtadmission"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" v-if="studentLeaveDetails.student">
                    <input
                      type="text"
                      disabled
                      v-model="studentLeaveDetails.student.firstName"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >Student Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" v-if="studentLeaveDetails.class">
                    <input
                      type="text"
                      disabled
                      v-model="studentLeaveDetails.class.className"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >Class <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" v-if="studentLeaveDetails.section">
                    <input
                      type="text"
                      disabled
                      v-model="studentLeaveDetails.section.name"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >Classroom <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="date"
                      disabled
                      v-model="studentLeaveDetails.from"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >From Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="date"
                      disabled
                      v-model="studentLeaveDetails.to"
                      onkeydown="return false"
                      class="form-control form-input"
                      id="stdDob"
                    />

                    <label for="deptid"
                      >To Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    {{  studentLeaveDetails.noOfDates ? studentLeaveDetails.noOfDates.length : ''  }}
                   
                    <label for="deptid"
                      >No Of Days </label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <textarea
                            class="form-control web-form-input"
                            style="min-height:135px;resize:none"
                            id="message"
                            disabled
                            v-model="studentLeaveDetails.notes"
                          ></textarea>
                  
                    <label for="deptid"
                      >Reason </label
                    >
                  </span>
                </div>
              </div>

              <div class="widjetfooter">
                <div class="text-center dk_iconsml">
                  <button type="button"  class="btn btncl btn-secondary clsmdl" style="background-color: green" @click.prevent="leaveAction(studentLeaveDetails,'Approved')">
                      <span>Approve</span>
                  </button>
                  <button type="button" class="btn btncl btn-secondary clsmdl" style="background-color: red" id="clbtn" @click.prevent="leaveAction(studentLeaveDetails,'Rejected')">
                       <span>Reject</span>
                  </button>
                  <button type="button" class="btn btncl clsmdl" id="clbtn" @click.prevent="closeActionPopUp">
                       <span>Close</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
         
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "teacher",

  data() {
    return {
        studentApplyLeaveRequest: [],
        studentLeaveDetails: {},
        sortedAttendanceList: []

    };
  },
  created() {
   // created() {
      this.getStudentLeaveApplyList()
      this.getStudentLeaveAriveList()
    //},
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },
  methods: {
    async getStudentLeaveApplyList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/getStudentLeaveApplyList",
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
          this.studentApplyLeaveRequest = result

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    previousRoute() {
      let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", data);
      this.$router.go(-1);
    },
    async getStudentLeaveAriveList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/getStudentLeaveAriveList",
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
          this.sortedAttendanceList = result

        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    openActionPopUp(data,index){
      this.studentLeaveDetails = data
      this.studentLeaveDetails.from = this.studentLeaveDetails.from.substring(0, 10);
      this.studentLeaveDetails.to = this.studentLeaveDetails.to.substring(0, 10);

      this.$bvModal.show("viewActionPopUp");
    },
    closeActionPopUp(){
      this.studentLeaveDetails = {}
      this.$bvModal.hide("viewActionPopUp");
    },
    async leaveAction(data,type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        data['actionType'] = type
        const response = await ViService.viXPost(
          "/home/leave/student/approved",
          data,
          userData.token
        );

        // const response = await ViService.viXGet(
        //   "/home/leave/staff/approved",
        //   userData.token
        // );
        if (response.isSuccess) {
          this.closeActionPopUp()
          this.$toasted.success(response.message);
          this.getStudentLeaveApplyList()
          this.getStudentLeaveAriveList()
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    }
  },
  components: {
    leaveRequest: () => import("./VileaveApproval.vue"),
    alertsAndNotifications: () => import("./ViAlertsAndNotifications.vue"),
    visitorTracking: () => import("../Profile/VisitorTracking.vue")

  },
};
</script>

